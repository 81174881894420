import { createContext, useContext } from 'react';
import axios from 'axios';


const UserContext = createContext(null);

export const UserProvider = ({currentUser, setUser, children }) => {

	const saveUser = userObject => {
		localStorage.setItem('user', userObject);
		axios.defaults.headers.common['Authorization'] = userObject ? `Bearer ${userObject}` : '';
		setUser(userObject);
	};

	const destroyUser = () => {
		localStorage.removeItem('user');
		setUser(null);
	}

	return (
		<UserContext.Provider value={{ user: currentUser, setUser: saveUser, destroyUser }}>
			{ children }
		</UserContext.Provider>
	);
};

export const useUser = () => useContext(UserContext);
