import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import EmojiPicker from 'emoji-picker-react';
import CloseIcon from '@mui/icons-material/Close';
import { Helmet } from "react-helmet";
import { loadEnterprise, updateEnterprise, deleteEnterprise } from "../api/enterprise";


function Enterprise() {
	const [showEmojiPicker, setShowEmojiPicker] = useState(false);
	let params = useParams();
	const queryKey = ['enterprises', `enterprise_${params.enterpriseId}`];
	const {data} = useQuery(queryKey, () => loadEnterprise(params.enterpriseId), { cacheTime: 0});
	const queryClient = useQueryClient();
	const {isLoading, mutate} = useMutation(async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const data = {
			...Object.fromEntries(formData),
		};
		data.color_enterprise.substring(1);
		await updateEnterprise(params.enterpriseId, data);
		return data;
	}, {
		onSuccess: (data) => {
			queryClient.invalidateQueries(queryKey);
			toast.success(
				`L'entreprise ${data.name_enterprise} a été modifiée avec succès`, {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				theme: "dark",
			});
		},
		onError: (err, _, context) => {
			queryClient.invalidateQueries(queryKey);
			toast.error('Erreur lors de la modification de l\'entreprise');
		},
	});

	const {mutate: deleteEnt} = useMutation(
		async ({id, name}) => {
			await deleteEnterprise(id);
			return name;
		}, {
			onSuccess: (data) => {
				queryClient.invalidateQueries(queryKey);
				toast.success(
					`L'entreprise ${data} a été licencié avec succès`, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					theme: "dark",
				});
			},
			onError: (err, _, context) => {
				const msg = err.response.data.msg || 'Erreur lors de la suppression de l\'entreprise';
				queryClient.invalidateQueries(queryKey);
				toast.error(msg);
			},
		}
	);

	const Confirmation = ({ closeToast, toastProps }) => (
		<div className="insideToast">
			<p>Êtes-vous certain de vouloir supprimer <span className="bold-gold">{toastProps.enterprise_name}</span> ?</p>
			<div className="insideToast__actions">
				<button className="btn btn--gold" onClick={() => { deleteEnt({id: toastProps.enterprise_id, name: toastProps.enterprise_name}); closeToast()}}>Oui</button>
				<button className="btn" onClick={() => closeToast()}>Non</button>
			</div>
		</div>
	);

	const showConfirm = async (id, name) => {
		toast.warning(<Confirmation />, {
			toastId: id,
			enterprise_id: id,
			enterprise_name: name,
			position: toast.POSITION.TOP_CENTER,
			autoClose: false,
			draggable: false,
			closeOnClick: false,
		});
	};

	const enterprise = data || null;
	const [selectedEmoji, setSelectedEmoji] = useState(enterprise?.emoji_enterprise);

	if (enterprise === null) {
		return (
			<div>Loading...</div>
		);
	}

	return (
		<div className="page">
			<Helmet>
				<title>CDT - {enterprise.name_enterprise}</title>
			</Helmet>
			<h1>{enterprise.name_enterprise}</h1>
			<Link className="btn mb10" to={'/entreprises'}>Retour</Link>
			<form onSubmit={mutate}>
				<div>
					<label htmlFor="name">Nom</label>
					<input id="name" type="text" name="name_enterprise" maxLength={255} defaultValue={enterprise.name_enterprise} />
				</div>
				<div>
					<label htmlFor="emoji">Emoji</label>
					<input id="emoji" type="text" name="emoji_enterprise" defaultValue={enterprise.emoji_enterprise} value={selectedEmoji} onChange={e => setSelectedEmoji(e.target.value)} onFocus={() => setShowEmojiPicker(true)} />
					<span className="closeEmojiPicker">
						{showEmojiPicker && (
							<CloseIcon fontSize="small" onClick={() => setShowEmojiPicker(false)} />
						)}
					</span>
				</div>
				<div style={{display: showEmojiPicker ? 'block' : 'none'}} className="emojiPicker">
					<EmojiPicker
						onEmojiClick={(emoji) => {setSelectedEmoji(emoji.emoji); setShowEmojiPicker(false);}}
						emojiStyle='native'
					/>
				</div>
				<div>
					<label htmlFor="color">Couleur</label>
					<input id="color" type="color" name="color_enterprise" defaultValue={'#' + enterprise.color_enterprise} />
				</div>
				<div>
					<label htmlFor="show_calculo">Visible sur la calculo</label>
					<input id="show_calculo" type="checkbox" name="show_calculo" defaultChecked={enterprise.show_calculo ? true : false} />
				</div>
				<div>
					<label htmlFor="facture_max_ardoise">Facture max de l'ardoise</label>
					<input id="facture_max_ardoise" type="number" name="facture_max_ardoise" min={0} max={999999} defaultValue={enterprise.facture_max_ardoise} />
					<span>$</span>
				</div>
				<div>
					<label htmlFor="info_ardoise">Informations de l'ardoise</label>
					<textarea id="info_ardoise" name="info_ardoise" maxLength={255} defaultValue={enterprise.info_ardoise} />
				</div>
				{!enterprise.deleted && (
					<div>
						<button className="btn btn--gold mr10" disabled={isLoading} type="submit">Modifier</button>
						<button className="btn" disabled={isLoading} type="button" onClick={() => showConfirm(enterprise.id_enterprise, enterprise.name_enterprise)}>Supprimer</button>
					</div>
				)}
			</form>
		</div>
	);
}

export default Enterprise;