import axios from "axios";

export async function loadEnterprises(deleted = true) {
	const r = await axios.get(`${process.env.REACT_APP_API_URL}/enterprise?deleted=${deleted ? 1 : 0 }`);
	return r.data.enterprises;
}

export async function loadEnterprise(enterpriseId) {
	const r = await axios.get(`${process.env.REACT_APP_API_URL}/enterprise/${enterpriseId}`);
	return r.data.enterprise;
}

export async function updateEnterprise(enterpriseId, data) {
	const r = await axios.patch(`${process.env.REACT_APP_API_URL}/enterprise/${enterpriseId}`, data);
	return r.data;
}

export async function deleteEnterprise(enterpriseId) {
	const r = await axios.patch(`${process.env.REACT_APP_API_URL}/enterprise/delete/${enterpriseId}`);
	return r.data;
}

export async function loadPrices() {
	const r = await axios.get(`${process.env.REACT_APP_API_URL}/enterprise/prices`);
	return r.data.prices;
}

export async function updatePrice(data) {
	const r = await axios.patch(`${process.env.REACT_APP_API_URL}/enterprise/price`, data);
	return r.data;
}
