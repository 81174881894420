import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useUser } from '../components/useUser';

function Home() {
	const navigate = useNavigate();
	const location = useLocation();
	const { user } = useUser();

	useEffect(() => {
		if (!user) {
			navigate('/login');
		}

		if (location.pathname !== '/') {
			navigate('/');
		}
	}, [user, navigate, location.pathname]);

	return(
		<div className="page">
			<Helmet>
				<title>CDT</title>
			</Helmet>
			<h1>Accueil</h1>
			<ul>Queques idées...
				<li> - Afficher les x dernières factures (ventes/achats)</li>
				<li> - Afficher les x derniers pleins</li>
				<li> - Afficher les employés en service</li>
				<li> - Quelques graphs ? ()</li>
				<li> - mettre un récap des commandes Arc ou SBC par semaine/mois ?</li>
				<li> - Avec une évolution ou non les  dernières semaines etc</li>
				<li> - L'évolution du CA grossiste et vente particulier</li>
			</ul>
		</div>
	);
}

export default Home;
