import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { useUser } from '../components/useUser';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import fallbackLogo from '../assets/img/CDT_icon_no-bg.png'

function NavBar() {
	const { user, destroyUser } = useUser();
	// TODO : with user info, decode jwt to get info (user_id, user_avatar, user_name)
	const [showMenu, setShowMenu] = useState(false);
	const decoded = jwtDecode(user);

	return (
		<>
			<nav className={`navbar ${showMenu ? '' : 'navbar--hide'}`}>
				<div className='navbar__user'>
					<div className='navbar__user-logo'>
						<object className='navbar__user-logo-image' data={'https://cdn.discordapp.com/avatars/' + decoded.user_id + '/' + decoded.user_avatar + '.png?size=128'} type='image/jpeg' width={128} height={128}>
							<img className='navbar__user-logo-image' src={fallbackLogo} alt='fallback-avatar' />
						</object>
					</div>
					<div className='navbar__user-name'>{decoded.user_name}</div>
				</div>
				<NavLink to='/' end className='link navbar__link'>Accueil</NavLink>
				<NavLink to='/employes' className='link navbar__link'>Employés</NavLink>
				<NavLink to='/entreprises' className='link navbar__link'>Entreprises</NavLink>
				<NavLink to='/produits' className='link navbar__link'>Produits</NavLink>
				<NavLink to='/groupes' className='link navbar__link'>Groupes de produits</NavLink>
				<NavLink to='/prices' className='link navbar__link'>Prix des produits</NavLink>
				<NavLink to='/vehicules' className='link navbar__link'>Véhicules</NavLink>
				<button className='btn btn--gold navbar--disconnect' onClick={() => destroyUser()}>Se déconnecter</button>
			</nav>
			<button className='show__navbar' onClick={() => setShowMenu(!showMenu)}>{showMenu ? <CloseIcon /> : <MenuIcon /> }</button>
		</>
	);
};

export default NavBar;
