import axios from "axios";

export async function loadEmployees(fired = true) {
	const r = await axios.get(`${process.env.REACT_APP_API_URL}/employee?fired=${fired ? 1 : 0 }`);
	return r.data.employees;
}

export async function loadEmployee(employeeId) {
	const r = await axios.get(`${process.env.REACT_APP_API_URL}/employee/${employeeId}`);
	return r.data.employee;
}

export async function updateEmployee(employeeId, data) {
	const r = await axios.patch(`${process.env.REACT_APP_API_URL}/employee/${employeeId}`, data);
	return r.data;
}

export async function fireEmployee(employeeId) {
	const r = await axios.patch(`${process.env.REACT_APP_API_URL}/employee/fire/${employeeId}`);
	return r.data;
}
