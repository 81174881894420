import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { loadPrices, loadEnterprises, updatePrice } from "../api/enterprise";
import { loadGroups } from "../api/group";
import WineBar from '@mui/icons-material/WineBar';


function Prices() {
	const queryClient = useQueryClient();
	const [groupId, setGroupId] = useState(0);
	const [enterpriseName, setEnterpriseName] = useState('');
	const queryKeyPrices = ['prices'];
	const queryKeyEnterprises = ['enterprises'];
	const queryKeyGroups = ['groups'];
	const {isFetching: isFetchingPrices, data: dataPrices} = useQuery(queryKeyPrices, () => loadPrices());
	const {isFetching: isFetchingEnterprises, data: dataEnterprises} = useQuery(queryKeyEnterprises, () => loadEnterprises(false));
	const {isFetching: isFetchingGroups, data: dataGroups} = useQuery(queryKeyGroups, () => loadGroups(false));

	const prices = dataPrices || [];
	const enterprises = dataEnterprises || [];
	const groups = dataGroups || [];

	const { mutate: handlePriceChange } = useMutation(
		async ({idProduct, idEnterprise, price}) => {
			return updatePrice({idProduct, idEnterprise, price});
		},
		{
			onSuccess: (data) => {
				queryClient.invalidateQueries(queryKeyPrices);
				toast.success(`${data.msg}`, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					theme: "dark",
				});
			},
			onError: (err, _, context) => {
				queryClient.invalidateQueries(queryKeyPrices);
				toast.error('Erreur lors de la modification du prix');
			},
		}
	);

	return(
		<div className="page">
			<Helmet>
				<title>CDT - Gestion des prix</title>
			</Helmet>
			<h1>Gestion des prix{(isFetchingPrices || isFetchingEnterprises || isFetchingGroups) && <WineBar className="spin ml5" sx={{color: "#f4c143"}} />}</h1>

			<div className="select">
				<select id="selectGroup" onChange={(g) => setGroupId(parseInt(g.target.value))} defaultValue={groupId}>
					{groups.map(g => (
						<option key={g.id_group} value={g.id_group}>{g.name_group}</option>
					))}
				</select>
				<label htmlFor="selectGroup">Produits du groupe à afficher</label>
			</div>

			<div className="select">
				<input id="name_enterprise" type="text" defaultValue={enterpriseName} onChange={(i) => setEnterpriseName(i.target.value)} />
				<label htmlFor="name_enterprise">Nom de l'entreprise</label>
			</div>

			<div className="table-fix-column">
				<table>
					<thead>
						<tr>
							<th key={0}></th>
							{groups.find(g => groupId === 0 ? true : g.id_group === groupId)?.products
								.map(p => (
									<th key={p.id_product}><span>{p.name_product}</span></th>
								))
							}
						</tr>
					</thead>
					<tbody>
						{enterprises.filter((e) => enterpriseName !== '' ? new RegExp(`.*${enterpriseName.toLowerCase()}.*`).test(e.name_enterprise.toLowerCase()) : true).map((e) => (
							<tr key={e.id_enterprise}>
								<td key={0}>{e.name_enterprise}</td>
								{groups.find(g => groupId === 0 ? true : g.id_group === groupId)?.products
									.map(prod => (
										<td key={prod.id_product}>
											<input
												className="input_price"
												type="number"
												defaultValue={prices.find(p => p.id_enterprise === e.id_enterprise && p.id_product === prod.id_product)?.enterprise_price || prod.default_price}
												onBlur={(event) => {
													if (parseInt(event.target.value) !== (prices.find(p => p.id_enterprise === e.id_enterprise && p.id_product === prod.id_product)?.enterprise_price || prod.default_price)) {
														handlePriceChange({idProduct: prod.id_product, idEnterprise: e.id_enterprise, price: parseInt(event.target.value)});
													}
												}}
											/>$
										</td>
									))
								}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default Prices;
