import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import EmojiPicker from 'emoji-picker-react';
import CloseIcon from '@mui/icons-material/Close';
import { loadProduct, updateProduct } from "../api/product";
import { loadGroups } from "../api/group";
import { deleteProduct } from "../api/product";


function Product() {
	const [showEmojiPicker, setShowEmojiPicker] = useState(false);
	let params = useParams();
	const queryKey = ['products', `product_${params.productId}`];
	const queryKeyGroups = ['groups'];
	const {data: dataProduct} = useQuery(queryKey, () => loadProduct(params.productId), { cacheTime: 0});
	const {data: dataGroups} = useQuery(queryKeyGroups, () => loadGroups());
	const queryClient = useQueryClient();
	const {isLoading, mutate} = useMutation(async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const data = {
			...Object.fromEntries(formData),
		};
		await updateProduct(params.productId, data);
		return data;
	}, {
		onSuccess: (data) => {
			queryClient.invalidateQueries(queryKey);
			toast.success(
				`Le produit ${data.name_product} a été modifié avec succès`, {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				theme: "dark",
			});
		},
		onError: (err, _, context) => {
			queryClient.invalidateQueries(queryKey);
			toast.error('Erreur lors de la modification du produit');
		},
	});

	const {mutate: deleteProd} = useMutation(
		async ({id, name}) => {
			await deleteProduct(id);
			return name;
		}, {
			onSuccess: (data) => {
				queryClient.invalidateQueries(queryKey);
				toast.success(
					`Le produit ${data} a été supprimé avec succès`, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					theme: "dark",
				});
			},
			onError: (err, _, context) => {
				queryClient.invalidateQueries(queryKey);
				toast.error('Erreur lors de la suppression du produit');
			},
		}
	);

	const Confirmation = ({ closeToast, toastProps }) => (
		<div className="insideToast">
			<p>Êtes-vous certain de vouloir supprimer le produit <span className="bold-gold">{toastProps.product_name}</span> ?</p>
			<div className="insideToast__actions">
				<button className="btn btn--gold" onClick={() => { deleteProd({id: toastProps.product_id, name: toastProps.product_name}); closeToast()}}>Oui</button>
				<button className="btn" onClick={() => closeToast()}>Non</button>
			</div>
		</div>
	);

	const showConfirm = async (id, name) => {
		toast.warning(<Confirmation />, {
			toastId: id,
			product_id: id,
			product_name: name,
			position: toast.POSITION.TOP_CENTER,
			autoClose: false,
			draggable: false,
			closeOnClick: false,
		})
	};

	const product = dataProduct || null;
	const groups = dataGroups || [];
	const [selectedEmoji, setSelectedEmoji] = useState(product?.emoji_product);


	if (product === null || groups === []) {
		return (
			<div>Loading...</div>
		);
	}

	return(
		<div className="page">
			<Helmet>
				<title>CDT - {product.name_product}</title>
			</Helmet>
			<h1>{product.name_product}</h1>
			<Link className="btn mb10" to={'/produits'}>Retour</Link>
			<form onSubmit={mutate}>
				<div>
					<label htmlFor="name">Nom</label>
					<input id="name" type="text" name="name_product" maxLength={255} defaultValue={product.name_product} />
				</div>
				<div>
					<label htmlFor="emoji">Emoji</label>
					<input id="emoji" type="text" name="emoji_product" defaultValue={product.emoji_product} value={selectedEmoji} onChange={e => setSelectedEmoji(e.target.value)} onFocus={() => setShowEmojiPicker(true)} />
					<span className="closeEmojiPicker">
						{showEmojiPicker && (
							<CloseIcon fontSize="small" onClick={() => setShowEmojiPicker(false)} />
						)}
					</span>
				</div>
				<div style={{display: showEmojiPicker ? 'block' : 'none'}} className="emojiPicker">
					<EmojiPicker
						onEmojiClick={(emoji) => {setSelectedEmoji(emoji.emoji); setShowEmojiPicker(false);}}
						emojiStyle='native'
					/>
				</div>
				<div>
					<label htmlFor="group">Rattaché à</label>
					<select id="group" name="id_group" defaultValue={product.id_group}>
						{groups.map(g => (
							<option key={g.id_group} value={g.id_group}>{g.name_group}</option>
						))}
					</select>
				</div>
				<div>
					<label htmlFor="available">Disponible</label>
					<input id="available" type="checkbox" name="is_available" defaultChecked={product.is_available ? true : false} />
				</div>
				<div>
					<label htmlFor="calculo_check">Check recette après calculo</label>
					<input id="calculo_check" type="checkbox" name="calculo_check" defaultChecked={product.calculo_check ? true : false} />
				</div>
				<div>
					<label htmlFor="default_price">Prix par défaut</label>
					<input id="default_price" type="number" name="default_price" defaultValue={product.default_price} />
					<span>$</span>
				</div>
				<div>
					<label htmlFor="qt_wanted">Quantité voulue</label>
					<input id="qt_wanted" type="number" name="qt_wanted" min={0} defaultValue={product.qt_wanted} />
				</div>
				{!product.deleted && (
					<div>
						<button className="btn btn--gold mr10" disabled={isLoading} type="submit">Modifier</button>
						<button className="btn" disabled={isLoading} type="button" onClick={() => showConfirm(product.id_product, product.name_product)}>Supprimer</button>
					</div>
				)}
			</form>
		</div>
	);
}

export default Product;