import axios from "axios";

export async function login(code) {
	try {
		const r = await axios.post(`${process.env.REACT_APP_API_URL}/token`, null, { params: { code } });
		return r;
	}
	catch (e) {
		throw e;
	}
}
