import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { loadEnterprises, deleteEnterprise } from "../api/enterprise";
import { useState } from "react";
import { Helmet } from "react-helmet";
import WineBar from '@mui/icons-material/WineBar';


function Enterprises() {
	const emoji_custom_regex = '^<?(a)?:?(\\w{2,32}):(\\d{17,19})>?$';
	const queryKey = ['enterprises'];
	const queryClient = useQueryClient();
	const {isFetching, data} = useQuery(queryKey, () => loadEnterprises());
	const [showDeleted, setShowDeleted] = useState(false);
	const {mutate} = useMutation(
		async ({id, name}) => {
			await deleteEnterprise(id);
			return name;
		}, {
			onSuccess: (data) => {
				queryClient.invalidateQueries(queryKey);
				toast.success(
					`L'entreprise ${data} a été supprimé avec succès`, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					theme: "dark",
				});
			},
			onError: (err, _, context) => {
				const msg = err.response.data.msg || 'Erreur lors de la suppression de l\'entreprise';
				queryClient.invalidateQueries(queryKey);
				toast.error(msg);
			},
		}
	);

	const Confirmation = ({ closeToast, toastProps }) => (
		<div className="insideToast">
			<p>Êtes-vous certain de vouloir supprimer <span className="bold-gold">{toastProps.enterprise_name}</span> ?</p>
			<div className="insideToast__actions">
				<button className="btn btn--gold" onClick={() => { mutate({id: toastProps.enterprise_id, name: toastProps.enterprise_name}); closeToast()}}>Oui</button>
				<button className="btn" onClick={() => closeToast()}>Non</button>
			</div>
		</div>
	);

	const showConfirm = async (id, name) => {
		toast.warning(<Confirmation />, {
			toastId: id,
			enterprise_id: id,
			enterprise_name: name,
			position: toast.POSITION.TOP_CENTER,
			autoClose: false,
			draggable: false,
			closeOnClick: false,
		});
	};

	const enterprises = data || [];

	return(
		<div className="page">
			<Helmet>
				<title>CDT - Entreprises</title>
			</Helmet>
			<h1>Entreprises{isFetching && <WineBar className="spin ml5" sx={{color: "#f4c143"}} />}</h1>

			<div className="checkbox">
				<input id="showDeleted" type="checkbox" onChange={(e) => setShowDeleted(e.target.checked)} value={showDeleted} />
				<label htmlFor="showDeleted">Afficher les entreprises supprimées</label>
			</div>

			<div className="card-container">
				{enterprises.filter(e => showDeleted || e.deleted !== true ).map((e) => (
						<div className="card" key={e.id_enterprise}>
							<div className="card__content">
								<h3>
									{e.emoji_enterprise ? e.emoji_enterprise.match(emoji_custom_regex) ?
										<span className="card__emoji"><img src={'https://cdn.discordapp.com/emojis/' + e.emoji_enterprise.match(emoji_custom_regex)[3] + '.png?size=32'} width={22} height={22} alt="emoji" /></span> :
										<span className="card__emoji">{e.emoji_enterprise}</span> : ''}
									{e.name_enterprise}
								</h3>
							</div>
							{!e.deleted && (
								<div className="card__actions">
									<Link className="btn mr10" to={`${e.id_enterprise}`}>Modifier</Link>
									<button className="btn" onClick={() => showConfirm(e.id_enterprise, e.name_enterprise)}>Supprimer</button>
								</div>
							)}
						</div>
					))}
			</div>
		</div>
	);
}

export default Enterprises;
