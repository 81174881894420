import { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { Helmet } from "react-helmet";
import { login } from '../api/login';
import { useUser } from '../components/useUser';
import discordLogo from '../assets/img/discord.svg'

const OAUTH_STATE_KEY = 'oauth2-state-key';

function Login() {
	const { user, setUser } = useUser();
	const navigate = useNavigate();
	let [searchParams] = useSearchParams();

	let error = null;

	useEffect(() => {
		if (!user) {
			return;
		}

		const decoded = jwtDecode(user);
		if (decoded?.user_id) {
			localStorage.removeItem(OAUTH_STATE_KEY);
			navigate('/');
		}
	}, [user, navigate]);

	if (searchParams.get('code')) {
		if (searchParams.get('state') === localStorage.getItem(OAUTH_STATE_KEY)) {
			login(searchParams.get('code')).then((data) => {
				const decoded = jwtDecode(data.data);
				if (decoded?.user_id) {
					// log success
					setUser(data.data);
				}
				else {
					// log failed
					error = data?.response?.data?.error;
					console.error('error: ', error || 'Une erreur s\'est produite lors de l\'authentification');
				}
			}).catch((e) => {
				error = e?.response?.data?.error;
				console.error('error: ', error || 'Une erreur s\'est produite lors de l\'authentification');
			});
		} else {
			console.error('error: ', error || 'Une erreur s\'est produite lors de l\'authentification');
			error = error || 'Une erreur s\'est produite lors de l\'authentification';
		}
	}

	const state = generateState();
	localStorage.setItem(OAUTH_STATE_KEY, state);

	return(
		<div className='login'>
			<Helmet>
				<title>CDT</title>
			</Helmet>
			<div className='login__cdt'>
			</div>
			<div className='login__content'>
				<span>Me connecter avec</span>
				<a className='login__logo' href={`https://discord.com/api/oauth2/authorize?client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=identify%20guilds%20guilds.members.read&state=${encodeURIComponent(state)}&prompt=none`}>
					<img src={discordLogo} alt='Discord logo'></img>
				</a>
				{error && <div>{error}</div>}
			</div>
		</div>
	);
}

const generateState = () => {
	let randomString = '';
	const randomNumber = Math.floor(Math.random() * 10);
	for (let i = 0; i < 20 + randomNumber; i++) {
		randomString += String.fromCharCode(33 + Math.floor(Math.random() * 94));
	}
	return randomString;
};

export default Login;
