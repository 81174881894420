import axios from "axios";

export async function loadVehicles() {
	const r = await axios.get(`${process.env.REACT_APP_API_URL}/vehicle`);
	return r.data.vehicles;
}

export async function loadVehicle(vehicleId) {
	const r = await axios.get(`${process.env.REACT_APP_API_URL}/vehicle/${vehicleId}`);
	return r.data.vehicle;
}

export async function updateVehicle(vehicleId, data) {
	const r = await axios.patch(`${process.env.REACT_APP_API_URL}/vehicle/${vehicleId}`, data);
	return r.data;
}

export async function deleteVehicle(vehicleId) {
	const r = await axios.patch(`${process.env.REACT_APP_API_URL}/vehicle/delete/${vehicleId}`);
	return r.data;
}