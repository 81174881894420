import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import EmojiPicker from 'emoji-picker-react';
import CloseIcon from '@mui/icons-material/Close';
import { loadGroup, updateGroup, deleteGroup } from "../api/group";


function Group() {
	const [showEmojiPicker, setShowEmojiPicker] = useState(false);
	let params = useParams();
	const navigate = useNavigate();
	const queryKey = ['groups', `group_${params.groupId}`];
	const {data} = useQuery(queryKey, () => loadGroup(params.groupId), { cacheTime: 0});
	const queryClient = useQueryClient();
	const {isLoading, mutate} = useMutation(async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const data = {
			...Object.fromEntries(formData),
		};
		await updateGroup(params.groupId, data);
		return data;
	}, {
		onSuccess: (data) => {
			queryClient.invalidateQueries(queryKey);
			toast.success(
				`Le groupe ${data.name_group} a été modifié avec succès`, {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				theme: "dark",
			});
		},
		onError: (err, _, context) => {
			queryClient.invalidateQueries(queryKey);
			toast.error('Erreur lors de la modification du groupe de produit');
		},
	});

	const {mutate: deletesGroup} = useMutation(
		async ({id, name}) => {
			await deleteGroup(id);
			return name;
		}, {
			onSuccess: (data) => {
				queryClient.invalidateQueries(queryKey);
				toast.success(
					`Le groupe ${data} a été supprimé avec succès`, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					theme: "dark",
				});
				navigate('/groupes');
			},
			onError: (err, _, context) => {
				queryClient.invalidateQueries(queryKey);
				toast.error('Erreur lors de la suppression du groupe');
			},
		}
	);

	const Confirmation = ({ closeToast, toastProps }) => (
		<div className="insideToast">
			<p>Êtes-vous certain de vouloir supprimer le groupe <span className="bold-gold">{toastProps.group_name}</span> ?</p>
			<div className="insideToast__actions">
				<button className="btn btn--gold" onClick={() => { deletesGroup({id: toastProps.group_id, name: toastProps.group_name}); closeToast()}}>Oui</button>
				<button className="btn" onClick={() => closeToast()}>Non</button>
			</div>
		</div>
	);

	const showConfirm = async (id, name) => {
		toast.warning(<Confirmation />, {
			toastId: id,
			group_id: id,
			group_name: name,
			position: toast.POSITION.TOP_CENTER,
			autoClose: false,
			draggable: false,
			closeOnClick: false,
		});
	};

	const group = data || null;
	const [selectedEmoji, setSelectedEmoji] = useState(group?.emoji_group);

	if (group === null) {
		return (
			<div>Loading...</div>
		);
	}

	return(
		<div className="page">
			<Helmet>
				<title>CDT - {group.name_group}</title>
			</Helmet>
			<h1>{group.name_group}</h1>
			<Link className="btn mb10" to={'/groupes'}>Retour</Link>
			<form onSubmit={mutate}>
				<div>
					<label htmlFor="name">Nom</label>
					<input id="name" type="text" name="name_group" maxLength={255} defaultValue={group.name_group} />
				</div>
				<div>
					<label htmlFor="emoji">Emoji</label>
					<input id="emoji" type="text" name="emoji_group" defaultValue={group.emoji_group} value={selectedEmoji} onChange={e => setSelectedEmoji(e.target.value)} onFocus={() => setShowEmojiPicker(true)} />
					<span className="closeEmojiPicker">
						{showEmojiPicker && (
							<CloseIcon fontSize="small" onClick={() => setShowEmojiPicker(false)} />
						)}
					</span>
				</div>
				<div style={{display: showEmojiPicker ? 'block' : 'none'}} className="emojiPicker">
					<EmojiPicker
						onEmojiClick={(emoji) => {setSelectedEmoji(emoji.emoji); setShowEmojiPicker(false);}}
						emojiStyle='native'
					/>
				</div>
				<div>
					<label htmlFor="default_group">Groupe par défaut</label>
					<input id="default_group" type="checkbox" name="default_group" defaultChecked={group.default_group ? true : false} />
				</div>
				<div>
					<button className="btn btn--gold mr10" disabled={isLoading} type="submit">Modifier</button>
					<button className="btn" disabled={isLoading} type="button" onClick={() => showConfirm(group.id_group, group.name_group)}>Supprimer</button>
				</div>
			</form>

			<p>Affiché la liste des produits sous ce groupe ?</p>
		</div>
	);
}

export default Group;