import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { loadVehicles, deleteVehicle } from "../api/vehicle";
import WineBar from '@mui/icons-material/WineBar';


function Vehicles() {
	const emoji_custom_regex = '^<?(a)?:?(\\w{2,32}):(\\d{17,19})>?$';
	const queryKey = ['vehicles'];
	const queryClient = useQueryClient();
	const {isFetching, data} = useQuery(queryKey, () => loadVehicles());
	const {mutate} = useMutation(
		async ({id, name}) => {
			await deleteVehicle(id);
			return name;
		}, {
			onSuccess: (data) => {
				queryClient.invalidateQueries(queryKey);
				toast.success(
					`Le véhicule ${data} a été supprimé avec succès`, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					theme: "dark",
				});
			},
			onError: (err, _, context) => {
				const msg = err.response.data.msg || 'Erreur lors de la suppression du véhicule';
				queryClient.invalidateQueries(queryKey);
				toast.error(msg);
			},
		}
	);

	const Confirmation = ({ closeToast, toastProps }) => (
		<div className="insideToast">
			<p>Êtes-vous certain de vouloir supprimer <span className="bold-gold">{toastProps.vehicle_name}</span> ?</p>
			<div className="insideToast__actions">
				<button className="btn btn--gold" onClick={() => { mutate({id: toastProps.vehicle_id, name: toastProps.vehicle_name}); closeToast()}}>Oui</button>
				<button className="btn" onClick={() => closeToast()}>Non</button>
			</div>
		</div>
	);

	const showConfirm = async (id, name) => {
		toast.warning(<Confirmation />, {
			toastId: id,
			enterprise_id: id,
			enterprise_name: name,
			position: toast.POSITION.TOP_CENTER,
			autoClose: false,
			draggable: false,
			closeOnClick: false,
		});
	};

	const vehicles = data || [];

	return (
		<div className="page">
			<Helmet>
				<title>CDT - Véhicules</title>
			</Helmet>
			<h1>Véhicules{isFetching && <WineBar className="spin ml5" sx={{color: "#f4c143"}} />}</h1>

			<div className="card-container">
				{vehicles.map((v) => (
					<div className="card" key={v.id_vehicle}>
						<div className="card__content">
							<h3>
								{v.emoji_vehicle ? v.emoji_vehicle.match(emoji_custom_regex) ?
									<span className="card__emoji"><img src={'https://cdn.discordapp.com/emojis/' + v.emoji_vehicle.match(emoji_custom_regex)[3] + '.png?size=32'} width={22} height={22} alt="emoji" /></span> :
									<span className="card__emoji">{v.emoji_vehicle}</span> : ''}
								{v.name_vehicle}
							</h3>
						</div>
						<div className="card__actions">
							<Link className="btn mr10" to={`${v.id_vehicle}`}>Modifier</Link>
							<button className="btn" onClick={() => showConfirm(v.id_vehicle, v.name_vehicle)}>Supprimer</button>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

export default Vehicles;
