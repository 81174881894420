import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { loadEmployees, fireEmployee } from "../api/employees";
import fallbackLogo from '../assets/img/CDT_icon_no-bg.png'
import WineBar from '@mui/icons-material/WineBar';

const moment = require('moment-timezone');
moment.tz.setDefault('Europe/Paris');
moment.updateLocale('fr', {
	week: {
		dow: 1,
		doy: 4,
	},
});


function Employees() {
	const queryKey = ['employees'];
	const queryClient = useQueryClient();
	const {isFetching, data, refetch} = useQuery(queryKey, () => loadEmployees(showFired));
	const [showFired, setShowFired] = useState(false);
	useEffect(() => { refetch()}, [refetch, showFired]);
	const [showData, setShowData] = useState(false);
	const [typeData, setTypeData] = useState(false);
	const [employeeName, setEmployeeName] = useState('');
	const {mutate} = useMutation(
		async ({id, name}) => {
			await fireEmployee(id);
			return name;
		}, {
			onSuccess: (data) => {
				queryClient.invalidateQueries(queryKey);
				toast.success(
					`L'employé ${data} a été licencié avec succès`, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					theme: "dark",
				});
			},
			onError: (err, _, context) => {
				queryClient.invalidateQueries(queryKey);
				toast.error('Erreur lors du licenciement de l\'employé');
			},
		}
	);

	const Confirmation = ({ closeToast, toastProps }) => (
		<div className="insideToast">
			<p>Êtes-vous certain de vouloir licencier <span className="bold-gold">{toastProps.employee_name}</span> ?</p>
			<div className="insideToast__actions">
				<button className="btn btn--gold" onClick={() => { mutate({id: toastProps.employee_id, name: toastProps.employee_name}); closeToast()}}>Oui</button>
				<button className="btn" onClick={() => closeToast()}>Non</button>
			</div>
		</div>
	);

	const showConfirm = async (id, name) => {
		toast.warning(<Confirmation />, {
			toastId: id,
			employee_id: id,
			employee_name: name,
			position: toast.POSITION.TOP_CENTER,
			autoClose: false,
			draggable: false,
			closeOnClick: false,
		});
	};

	let employees = data || [];

	employees = employees.filter((e) => employeeName !== '' ? new RegExp(`.*${employeeName.toLowerCase()}.*`).test(e.employee.name_employee.toLowerCase()) : true);

	return(
		<div className="page">
			<Helmet>
				<title>CDT - Employés</title>
			</Helmet>
			<h1>Employés{isFetching && <WineBar className="spin ml5" sx={{color: "#f4c143"}} />}</h1>

			<div className="select">
				<input id="name_employee" type="text" defaultValue={employeeName} onChange={(i) => setEmployeeName(i.target.value.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&"))} />
				<label htmlFor="name_employee">Nom de l'employé</label>
			</div>

			<div className="checkbox">
				<input id="showFired" type="checkbox" onChange={(e) => setShowFired(e.target.checked)} value={showFired} />
				<label htmlFor="showFired">Afficher les employés licenciés</label>
			</div>

			<div className="checkbox">
				<input id="showData" type="checkbox" onChange={(e) => setShowData(e.target.checked)} value={showData} />
				<label htmlFor="showData">Afficher des données supplémentaires</label>
			</div>

			{showData && (
				<div className="checkbox">
					<input id="typeData" type="checkbox" className="switch" onChange={(e) => setTypeData(e.target.checked)} defaultChecked={typeData} value={typeData} />
					<label htmlFor="typeData">{typeData ? 'Données de travail' : 'Infos utiles'}</label>
				</div>
			)}

			<div className="card-container">
				{employees.filter(e => showFired || e.employee.date_firing === null ).map((e) => (
						<div className="card" key={e.employee.id}>
							<div className='card__logo' style={e.employee.embed_color >= 0 ? { "borderColor": `#${e.employee.embed_color.toString(16).padStart(6, '0')}`} : { "borderColor": '#2C2F33'}}>
								<object className='card__logo-image' data={e.employee.pp_url} type='image/jpeg' width={48} height={48}>
									<img className='card__logo-image' src={fallbackLogo} alt='fallback-avatar' />
								</object>
							</div>
							<div className="card__content">
								<h3>
									{e.employee.name_employee}
								</h3>
								<p>Contrat : {e.employee.contract}</p>
								<p>Salaire : ${e.employee.wage}</p>
								<p>Téléphone : 555-{e.employee.phone_number}</p>
								{showData && (typeData ? (
									<div className="card__content--column">
										<div>
											<p>Tournées</p>
											<p>S : {e.grossiste[0] ? (e.grossiste[0] / 720).toFixed(2) : 0}</p>
											<p>S-1 : {e.grossiste[1] ? (e.grossiste[1] / 720).toFixed(2) : 0}</p>
											<p>S-2 : {e.grossiste[2] ? (e.grossiste[2] / 720).toFixed(2) : 0}</p>
											<p>S-3 : {e.grossiste[3] ? (e.grossiste[3] / 720).toFixed(2) : 0}</p>
										</div>
										<div>
											<p>Livraisons</p>
											<p>S : {e.nb_livraison[0]}</p>
											<p>S-1 : {e.nb_livraison[1]}</p>
											<p>S-2 : {e.nb_livraison[2]}</p>
											<p>S-3 : {e.nb_livraison[3]}</p>
										</div>
									</div>
								) : (
									<div className="mt10">
										<p>Permis PL : {e.employee.diploma ? '✅' : '❌'}</p>
										<p>Diplôme : {e.employee.driving_licence ? '✅' : '❌'}</p>
										<p>Visite médicale : {
											!e.employee.date_medical_checkup ?
												'Pas encore passé ⚠️' :
												(moment().diff(moment(e.employee.date_medical_checkup), 'd') > 118) ?
													`${moment(e.employee.date_medical_checkup).format('DD/MM/YYYY')} ⚠️` :
													`${moment(e.employee.date_medical_checkup).format('DD/MM/YYYY')}`
											}
										</p>
										<p>Embauché le : {moment(e.employee.date_hiring).format('DD/MM/YYYY')}</p>
										{e.employee.date_cdd ? <p>CDD : {moment(e.employee.date_cdd).format('DD/MM/YYYY')}</p> : ''}
										{e.employee.date_cdi ? <p>CDI : {moment(e.employee.date_cdi).format('DD/MM/YYYY')}</p> : ''}
										{e.employee.date_firing ? <p>Licenciement : {moment(e.employee.date_firing).format('DD/MM/YYYY')}</p> : ''}
									</div>
								))}
							</div>
							{!e.employee.date_firing && (
								<div className="card__actions">
									<Link className="btn mr10" to={`${e.employee.id}`}>Modifier</Link>
									<button className="btn" onClick={() => showConfirm(e.employee.id, e.employee.name_employee)}>Licencier</button>
								</div>
							)}
						</div>
					))}
			</div>
		</div>
	);
}

export default Employees;
