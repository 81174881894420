import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { UserProvider } from './components/useUser';
import axios from 'axios';
import NavBar from './components/NavBar';
import Home from './pages/Home';
import Login from './pages/Login';
import Enterprises from './pages/Enterprises';
import Employees from './pages/Employees';
import Employee from './pages/Employee';
import Enterprise from './pages/Enterprise';
import Prices from './pages/Prices';
import Products from './pages/Products';
import Product from './pages/Product';
import Groups from './pages/Groups';
import Group from './pages/Group';
import Vehicles from './pages/Vehicles';
import Vehicle from './pages/Vehicle';
import 'react-toastify/dist/ReactToastify.css';
import './styles/app.scss';

function App() {
	const [user, setUser] = useState(localStorage.getItem('user'));

	if (!user) {
		return (
			<UserProvider currentUser={user} setUser={setUser}>
				<BrowserRouter>
					<Routes>
						<Route path='/login' element={<Login />} />
						<Route path='*' element={<Home />} />
					</Routes>
				</BrowserRouter>
			</UserProvider>
		);
	}

	axios.defaults.headers.common['Authorization'] = user ? `Bearer ${user}` : '';
	return (
		<UserProvider currentUser={user} setUser={setUser}>
			<BrowserRouter>
				<NavBar />
				<Routes>
					<Route path='/' element={<Home />} />
					<Route path='/login' element={<Login />} />
					<Route path='employes' element={<Employees />} />
					<Route path='employes/:employeeId' element={<Employee />} />
					<Route path='entreprises' element={<Enterprises />} />
					<Route path='entreprises/:enterpriseId' element={<Enterprise />} />
					<Route path='produits' element={<Products />} />
					<Route path='produits/:productId' element={<Product />} />
					<Route path='groupes' element={<Groups />} />
					<Route path='groupes/:groupId' element={<Group />} />
					<Route path='vehicules' element={<Vehicles />} />
					<Route path='vehicules/:vehicleId' element={<Vehicle />} />
					<Route path='prices' element={<Prices />} />
					<Route path='*' element={<Home />} />
				</Routes>
				<ToastContainer
					position={toast.POSITION.TOP_RIGHT}
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					pauseOnHover
					theme="dark"
					limit={5}
				/>
			</BrowserRouter>
		</UserProvider>
	);
}

export default App;
