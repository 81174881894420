import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { loadGroups } from "../api/group";
import { loadProducts, deleteProduct } from "../api/product";
import WineBar from '@mui/icons-material/WineBar';


function Products() {
	const emoji_custom_regex = '^<?(a)?:?(\\w{2,32}):(\\d{17,19})>?$';
	const queryKeyGroups = ['groups'];
	const queryKeyProducts = ['products'];
	const queryClient = useQueryClient();
	const {isFetching: isFetchingGroups, data: dataGroups} = useQuery(queryKeyGroups, () => loadGroups());
	const {isFetching: isFetchingProducts, data: dataProducts} = useQuery(queryKeyProducts, () => loadProducts());
	const [groupId, setGroupId] = useState(0);
	const [showDeleted, setShowDeleted] = useState(false);

	const {mutate} = useMutation(
		async ({id, name}) => {
			await deleteProduct(id);
			return name;
		}, {
			onSuccess: (data) => {
				queryClient.invalidateQueries(queryKeyGroups);
				queryClient.invalidateQueries(queryKeyProducts);
				toast.success(
					`Le produit ${data} a été supprimé avec succès`, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					theme: "dark",
				});
			},
			onError: (err, _, context) => {
				queryClient.invalidateQueries(queryKeyGroups);
				queryClient.invalidateQueries(queryKeyProducts);
				toast.error('Erreur lors de la suppression du produit');
			},
		}
	);

	const Confirmation = ({ closeToast, toastProps }) => (
		<div className="insideToast">
			<p>Êtes-vous certain de vouloir supprimer le produit <span className="bold-gold">{toastProps.product_name}</span> ?</p>
			<div className="insideToast__actions">
				<button className="btn btn--gold" onClick={() => { mutate({id: toastProps.product_id, name: toastProps.product_name}); closeToast()}}>Oui</button>
				<button className="btn" onClick={() => closeToast()}>Non</button>
			</div>
		</div>
	);

	const showConfirm = async (id, name) => {
		toast.warning(<Confirmation />, {
			toastId: id,
			product_id: id,
			product_name: name,
			position: toast.POSITION.TOP_CENTER,
			autoClose: false,
			draggable: false,
			closeOnClick: false,
		});
	};

	const groups = dataGroups || [];
	const products = dataProducts || [];

	return(
		<div className="page">
			<Helmet>
				<title>CDT - Produits</title>
			</Helmet>
			<h1>Produits{(isFetchingGroups || isFetchingProducts) && <WineBar className="spin ml5" sx={{color: "#f4c143"}} />}</h1>

			<div className="checkbox">
				<input id="showDeleted" type="checkbox" onChange={(e) => setShowDeleted(e.target.checked)} value={showDeleted} />
				<label htmlFor="showDeleted">Afficher les produits supprimés</label>
			</div>

			<div className="select">
				<select id="selectGroup" onChange={(g) => setGroupId(parseInt(g.target.value))} defaultValue={groupId}>
					<option key={0} value={0}>Sans groupe</option>
					{groups.map(g => (
						<option key={g.id_group} value={g.id_group}>{g.name_group}</option>
					))}
				</select>
				<label htmlFor="selectGroup">Produits du groupe à afficher</label>
			</div>

			<div className="card-container">
				{products
					.filter(p => groupId === 0 ? p.id_group === null : p.id_group === groupId)
					.filter(p => showDeleted || p.deleted !== true )
					.map((p) => (
						<div className="card" key={p.id_product}>
							<div className="card__content">
								<h3>
									{p.emoji_product ? p.emoji_product.match(emoji_custom_regex) ?
										<span className="card__emoji"><img src={'https://cdn.discordapp.com/emojis/' + p.emoji_product.match(emoji_custom_regex)[3] + '.png?size=32'} width={22} height={22} alt="emoji" /></span> :
										<span className="card__emoji">{p.emoji_product}</span> : ''}
									{p.name_product}
								</h3>
							</div>
							{!p.deleted && (
								<div className="card__actions">
									<Link className="btn mr10" to={`${p.id_product}`}>Modifier</Link>
									<button className="btn" onClick={() => showConfirm(p.id_product, p.name_product)}>Supprimer</button>
								</div>
							)}
						</div>
					))}
			</div>
		</div>
	);
}

export default Products;
