import axios from 'axios';

export async function loadGroups(deletedProduct = true) {
	const r = await axios.get(`${process.env.REACT_APP_API_URL}/group?deletedProduct=${deletedProduct ? 1 : 0 }`);
	return r.data.groups;
}

export async function loadGroup(groupId) {
	const r = await axios.get(`${process.env.REACT_APP_API_URL}/group/${groupId}`);
	return r.data.group;
}

export async function updateGroup(groupId, data) {
	const r = await axios.patch(`${process.env.REACT_APP_API_URL}/group/${groupId}`, data);
	return r.data;
}

export async function deleteGroup(groupId) {
	const r = await axios.patch(`${process.env.REACT_APP_API_URL}/group/delete/${groupId}`);
	return r.data;
}