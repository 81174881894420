import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import { loadEmployee, updateEmployee, fireEmployee } from "../api/employees";

const moment = require('moment-timezone');
moment.tz.setDefault('Europe/Paris');
moment.updateLocale('fr', {
	week: {
		dow: 1,
		doy: 4,
	},
});

function Employee() {
	let params = useParams();
	const queryKey = ['employees', `employee_${params.employeeId}`];
	const {data} = useQuery(queryKey, () => loadEmployee(params.employeeId), { cacheTime: 0});
	const queryClient = useQueryClient();
	const {isLoading, mutate} = useMutation(async (e) => {
		e.preventDefault();
		if (employee.date_firing) {
			throw new Error('Impossible de modifier un employé licencié');
		}
		const formData = new FormData(e.target);
		const data = {
			...Object.fromEntries(formData),
		};
		data.phone_number = data.phone_number.replace('555-', '').slice(-4).padStart(4, '0');
		await updateEmployee(params.employeeId, data);
		return data;
	}, {
		onSuccess: (data) => {
			queryClient.invalidateQueries(queryKey);
			toast.success(
				`L'employé ${data.name_employee} a été modifiée avec succès`, {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				theme: "dark",
			});
		},
		onError: (err, _, context) => {
			queryClient.invalidateQueries(queryKey);
			toast.error('Erreur lors de la modification de l\'employé');
		},
	});

	const {mutate: fire} = useMutation(
		async ({id, name}) => {
			await fireEmployee(id);
			return name;
		}, {
			onSuccess: (data) => {
				queryClient.invalidateQueries(queryKey);
				toast.success(
					`L'employé ${data} a été licencié avec succès`, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					theme: "dark",
				});
			},
			onError: (err, _, context) => {
				queryClient.invalidateQueries(queryKey);
				toast.error('Erreur lors du licenciement de l\'employé');
			},
		}
	);

	const Confirmation = ({ closeToast, toastProps }) => (
		<div className="insideToast">
			<p>Êtes-vous certain de vouloir licencier <span className="bold-gold">{toastProps.employee_name}</span> ?</p>
			<div className="insideToast__actions">
				<button className="btn btn--gold" onClick={() => { fire({id: toastProps.employee_id, name: toastProps.employee_name}); closeToast()}}>Oui</button>
				<button className="btn" onClick={() => closeToast()}>Non</button>
			</div>
		</div>
	);

	const showConfirm = async (id, name) => {
		toast.warning(<Confirmation />, {
			toastId: id,
			employee_id: id,
			employee_name: name,
			position: toast.POSITION.TOP_CENTER,
			autoClose: false,
			draggable: false,
			closeOnClick: false,
		});
	};

	const employee = data || null;

	if (employee === null) {
		return (
			<div>Loading...</div>
		);
	}

	return(
		<div className="page">
			<Helmet>
				<title>CDT - {employee.name_employee}</title>
			</Helmet>
			<h1>{employee.name_employee}</h1>
			<Link className="btn mb10" to={'/employes'}>Retour</Link>
			<form onSubmit={mutate}>
				<div>
					<label htmlFor="name">Nom</label>
					<input id="name" type="text" name="name_employee" maxLength={255} defaultValue={employee.name_employee} />
				</div>
				<div>
					<label htmlFor="contract">Contrat</label>
					<input id="contract" type="text" name="contract" disabled defaultValue={employee.contract} />
				</div>
				<div>
					<label htmlFor="wage">Salaire</label>
					<input id="wage" type="number" name="wage" min={0} max={999999} defaultValue={employee.wage} />
					<span>$</span>
				</div>
				<div>
					<label htmlFor="phone_number">Téléphone</label>
					<input id="phone_number" type="text" name="phone_number" maxLength={8} defaultValue={`555-${employee.phone_number}`} />
				</div>
				<div>
					<label htmlFor="driving_licence">Permis PL</label>
					<input id="driving_licence" type="checkbox" name="driving_licence" defaultChecked={employee.driving_licence ? true : false} />
				</div>
				<div>
					<label htmlFor="diploma">Diplôme</label>
					<input id="diploma" type="checkbox" name="diploma" defaultChecked={employee.diploma ? true : false} />
				</div>
				<div>
					<label htmlFor="date_hiring">Embauché le</label>
					<input id="date_hiring" name="date_hiring" type="date" defaultValue={moment(employee.date_hiring).format('YYYY-MM-DD')} />
				</div>
				<div>
					<label htmlFor="date_cdd">Passage en CDD</label>
					<input id="date_cdd" name="date_cdd" type="date" defaultValue={moment(employee.date_cdd).format('YYYY-MM-DD')} />
				</div>
				<div>
					<label htmlFor="date_cdi">Passage en CDI</label>
					<input id="date_cdi" name="date_cdi" type="date" defaultValue={moment(employee.date_cdi).format('YYYY-MM-DD')} />
				</div>
				<div>
					<label htmlFor="medical_checkup">Visite médicale</label>
					<input id="medical_checkup" name="date_medical_checkup" type="date" defaultValue={moment(employee.date_medical_checkup).format('YYYY-MM-DD')} />
				</div>
				{!employee.date_firing && (
					<div>
						<button className="btn btn--gold mr10" disabled={isLoading} type="submit">Modifier</button>
						<button className="btn" disabled={isLoading} type="button" onClick={() => showConfirm(employee.id, employee.name_employee)}>Licencier</button>
					</div>
				)}
			</form>
		</div>
	);
}

export default Employee;