import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { loadGroups, deleteGroup } from "../api/group";
import { loadProducts, moveProduct } from "../api/product";
import WineBar from '@mui/icons-material/WineBar';


function Groups() {
	const emoji_custom_regex = '^<?(a)?:?(\\w{2,32}):(\\d{17,19})>?$';
	const queryKeyGroups = ['groups'];
	const queryKeyProductsNoGroup = ['products_no_group']
	const queryClient = useQueryClient();
	const {isFetching: isFetchingGroups, data: dataGroups} = useQuery(queryKeyGroups, () => loadGroups(false));
	const {isFetching: isFetchingProducts, data: dataProducts} = useQuery(queryKeyProductsNoGroup, () => loadProducts(false, true));
	const [showProducts, setShowProducts] = useState(false);
	const [productDragged, setProductDragged] = useState(null);

	const {mutate: deletesGroup} = useMutation(
		async ({id, name}) => {
			await deleteGroup(id);
			return name;
		}, {
			onSuccess: (data) => {
				queryClient.invalidateQueries(queryKeyGroups);
				queryClient.invalidateQueries(queryKeyProductsNoGroup);
				toast.success(
					`Le groupe ${data} a été supprimé avec succès`, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					theme: "dark",
				});
			},
			onError: (err, _, context) => {
				queryClient.invalidateQueries(queryKeyGroups);
				queryClient.invalidateQueries(queryKeyProductsNoGroup);
				toast.error('Erreur lors de la suppression du groupe');
			},
		}
	);

	const {mutate: moveProd} = useMutation(
		async ({id_group, name_group, id_product, name_product}) => {
			await moveProduct(id_product, { id_group: id_group});
			return {name_product, name_group};
		}, {
			onSuccess: (data) => {
				queryClient.invalidateQueries(queryKeyGroups);
				queryClient.invalidateQueries(queryKeyProductsNoGroup);
				toast.success(
					`Le produit ${data.name_product} a été déplacé dans le groupe ${data.name_group} avec succès`, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					theme: "dark",
				});
			},
			onError: (err, _, context) => {
				queryClient.invalidateQueries(queryKeyGroups);
				queryClient.invalidateQueries(queryKeyProductsNoGroup);
				toast.error('Erreur lors du déplacement du produit');
			},
		}
	);

	const Confirmation = ({ closeToast, toastProps }) => (
		<div className="insideToast">
			<p>Êtes-vous certain de vouloir supprimer le groupe <span className="bold-gold">{toastProps.group_name}</span> ?</p>
			<div className="insideToast__actions">
				<button className="btn btn--gold" onClick={() => { deletesGroup({id: toastProps.group_id, name: toastProps.group_name}); closeToast()}}>Oui</button>
				<button className="btn" onClick={() => closeToast()}>Non</button>
			</div>
		</div>
	);

	const showConfirm = async (id, name) => {
		toast.warning(<Confirmation />, {
			toastId: id,
			group_id: id,
			group_name: name,
			position: toast.POSITION.TOP_CENTER,
			autoClose: false,
			draggable: false,
			closeOnClick: false,
		});
	};

	const groups = dataGroups || [];
	const products = dataProducts || [];

	const onDragStart = (event, id_product, name_product, id_group) => {
		setProductDragged({id_product, name_product, id_group});
	}

	const onDragOver = (event) => {
		event.preventDefault();
	}

	const onDrop = (event, id_group, name_group) => {
		if (productDragged && productDragged.id_group !== id_group) {
			moveProd({id_group: id_group, name_group: name_group, id_product: productDragged.id_product, name_product: productDragged.name_product});
			setProductDragged(null);
		}
	}

	return(
		<div className="page">
			<Helmet>
				<title>CDT - Groupes de produits</title>
			</Helmet>
			<h1>Groupes de produits{(isFetchingGroups || isFetchingProducts) && <WineBar className="spin ml5" sx={{color: "#f4c143"}} />}</h1>

			<div className="checkbox">
				<input id="showProducts" type="checkbox" onChange={(e) => setShowProducts(e.target.checked)} value={showProducts} />
				<label htmlFor="showProducts">Afficher les produits</label>
			</div>

			<div className="card-container">
				{groups.map((g) => (
						<div className="card" key={g.id_group}>
							<div className="card__content">
								<h3>
									{g.emoji_group ? g.emoji_group.match(emoji_custom_regex) ?
										<span className="card__emoji"><img src={'https://cdn.discordapp.com/emojis/' + g.emoji_group.match(emoji_custom_regex)[3] + '.png?size=32'} width={22} height={22} alt="emoji" /></span> :
										<span className="card__emoji">{g.emoji_group}</span> : ''}
									{g.name_group} {g.default_group && '✅'}
								</h3>
								<p>Nombre de produits : {g.products.length}</p>
								{showProducts && (
									<>
										<h4>Produits</h4>
										<ul key={g.id_group} className="drop-zone" onDrop={(e) => onDrop(e, g.id_group, g.name_group)} onDragOver={onDragOver}>
											{g.products.map(p => (
												<li key={p.id_product} className="draggable" draggable={true} onDragStart={(e) => onDragStart(e, p.id_product, p.name_product, p.id_group)}><DragIndicatorIcon fontSize="small" />{p.name_product}</li>
											))}
										</ul>
									</>
								)}
							</div>
							<div className="card__actions">
								<Link className="btn mr10" to={`${g.id_group}`}>Modifier</Link>
								<button className="btn" onClick={() => showConfirm(g.id_group, g.name_group)}>Supprimer</button>
							</div>
						</div>
					))}
				{showProducts && (
					<div className="card" key={0}>
						<div className="card__content">
							<h3>Produits sans groupe</h3>
							<p>Nombre de produits : {products.length}</p>
							<h4>Produits</h4>
							<ul key={0} className="drop-zone" onDrop={(e) => onDrop(e, null, 'sans groupe')} onDragOver={onDragOver}>
								{products.map(p => (
									<li key={p.id_product} className="draggable" draggable={true} onDragStart={(e) => onDragStart(e, p.id_product, p.name_product, p.id_group)}><DragIndicatorIcon fontSize="small" />{p.name_product}</li>
								))}
							</ul>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default Groups;
