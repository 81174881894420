import axios from "axios";

export async function loadProducts(deleted = true, no_group = false) {
	const r = await axios.get(`${process.env.REACT_APP_API_URL}/product?deleted=${deleted ? 1 : 0 }&no_group=${no_group ? 1 : 0 }`);
	return r.data.products;
}

export async function loadProduct(productId) {
	const r = await axios.get(`${process.env.REACT_APP_API_URL}/product/${productId}`);
	return r.data.product;
}

export async function updateProduct(productId, data) {
	const r = await axios.patch(`${process.env.REACT_APP_API_URL}/product/${productId}`, data);
	return r.data;
}

export async function moveProduct(productId, data) {
	const r = await axios.patch(`${process.env.REACT_APP_API_URL}/product/move/${productId}`, data);
	return r.data;
} 

export async function deleteProduct(productId) {
	const r = await axios.patch(`${process.env.REACT_APP_API_URL}/product/delete/${productId}`);
	return r.data;
}
