import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import EmojiPicker from 'emoji-picker-react';
import CloseIcon from '@mui/icons-material/Close';
import { loadVehicle, updateVehicle, deleteVehicle } from "../api/vehicle";


function Vehicle() {
	const [showEmojiPicker, setShowEmojiPicker] = useState(false);
	const [showCustomMsg, setShowCustomMsg] = useState(null);
	let params = useParams();
	const queryKey = ['vehicles', `vehicle_${params.vehicleId}`];
	const {data} = useQuery(queryKey, () => loadVehicle(params.vehicleId), { cacheTime: 0});
	const queryClient = useQueryClient();
	const {isLoading, mutate} = useMutation(async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		const data = {
			...Object.fromEntries(formData),
		};
		data.color_vehicle.substring(1);
		await updateVehicle(params.vehicleId, data);
		return data;
	}, {
		onSuccess: (data) => {
			queryClient.invalidateQueries(queryKey);
			toast.success(
				`Le véhicule ${data.name_vehicle} a été modifié avec succès`, {
				position: toast.POSITION.TOP_RIGHT,
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				theme: "dark",
			});
		},
		onError: (err, _, context) => {
			queryClient.invalidateQueries(queryKey);
			toast.error('Erreur lors de la modification du véhicule');
		},
	});

	const {mutate: deleteVeh} = useMutation(
		async ({id, name}) => {
			await deleteVehicle(id);
			return name;
		}, {
			onSuccess: (data) => {
				queryClient.invalidateQueries(queryKey);
				toast.success(
					`Le véhicule ${data} a été supprimé avec succès`, {
					position: toast.POSITION.TOP_RIGHT,
					autoClose: 5000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					theme: "dark",
				});
			},
			onError: (err, _, context) => {
				const msg = err.response.data.msg || 'Erreur lors de la suppression du véhicule';
				queryClient.invalidateQueries(queryKey);
				toast.error(msg);
			},
		}
	);

	const Confirmation = ({ closeToast, toastProps }) => (
		<div className="insideToast">
			<p>Êtes-vous certain de vouloir supprimer <span className="bold-gold">{toastProps.vehicle_name}</span> ?</p>
			<div className="insideToast__actions">
				<button className="btn btn--gold" onClick={() => { deleteVeh({id: toastProps.vehicle_id, name: toastProps.vehicle_name}); closeToast()}}>Oui</button>
				<button className="btn" onClick={() => closeToast()}>Non</button>
			</div>
		</div>
	);

	const showConfirm = async (id, name) => {
		toast.warning(<Confirmation />, {
			toastId: id,
			vehicle_id: id,
			vehicle_name: name,
			position: toast.POSITION.TOP_CENTER,
			autoClose: false,
			draggable: false,
			closeOnClick: false,
		});
	};

	const vehicle = data || null;
	const [selectedEmoji, setSelectedEmoji] = useState(vehicle?.emoji_vehicle);
	const reasons = [
		'Oui',
		'Au garage public de l\'aéroport',
		'En fourrière',
		'Détruit',
		'En attente de la réponse de l\'assurance',
	];

	if (vehicle === null) {
		return (
			<div>Loading...</div>
		);
	}

	return (
		<div className="page">
			<Helmet>
				<title>CDT - {vehicle.name_vehicle}</title>
			</Helmet>
			<h1>{vehicle.name_vehicle}</h1>
			<Link className="btn mb10" to={'/vehicules'}>Retour</Link>
			<form onSubmit={mutate}>
				<div>
					<label htmlFor="name">Nom</label>
					<input id="name" type="text" name="name_vehicle" maxLength={255} defaultValue={vehicle.name_vehicle} />
				</div>
				<div>
					<label htmlFor="emoji">Emoji</label>
					<input id="emoji" type="text" name="emoji_vehicle" defaultValue={vehicle.emoji_vehicle} value={selectedEmoji} onChange={e => setSelectedEmoji(e.target.value)} onFocus={() => setShowEmojiPicker(true)} />
					<span className="closeEmojiPicker">
						{showEmojiPicker && (
							<CloseIcon fontSize="small" onClick={() => setShowEmojiPicker(false)} />
						)}
					</span>
				</div>
				<div style={{display: showEmojiPicker ? 'block' : 'none'}} className="emojiPicker">
					<EmojiPicker
						onEmojiClick={(emoji) => {setSelectedEmoji(emoji.emoji); setShowEmojiPicker(false);}}
						emojiStyle='native'
					/>
				</div>
				<div>
					<label htmlFor="color">Couleur</label>
					<input id="color" type="color" name="color_vehicle" defaultValue={'#' + vehicle.color_vehicle} />
				</div>
				<div>
					<label htmlFor="nb_place">Nombre de places</label>
					<input id="nb_place" type="number" name="nb_place_vehicle" min={1} max={50} defaultValue={vehicle.nb_place_vehicle} />
				</div>
				<div>
					<label htmlFor="to_repair">À réparer</label>
					<input id="to_repair" type="checkbox" name="to_repair" defaultChecked={vehicle.to_repair ? true : false} />
				</div>

				<div>
					<label htmlFor="available_reason">Disponibilité</label>
					<select id="available_reason" name="available_reason" defaultValue={null} onChange={e => setShowCustomMsg(e.target.value === 'custom')}>
						{reasons.map((v, k) => (
							<option key={k} value={v}>{v}</option>
						))}
						<option key={reasons.length} value={'custom'}>Autre (à préciser)</option>
					</select>
				</div>

				{showCustomMsg && (
					<div>
						<label htmlFor="custom_reason">Veuillez préciser</label>
						<input id="custom_reason" type="text" name="custom_reason" maxLength={255} defaultValue={reasons.includes(vehicle.available_reason) ? '' : vehicle.available_reason} />
					</div>
				)}

				<div>
					<button className="btn btn--gold mr10" disabled={isLoading} type="submit">Modifier</button>
					<button className="btn" disabled={isLoading} type="button" onClick={() => showConfirm(vehicle.id_vehicle, vehicle.name_vehicle)}>Supprimer</button>
				</div>
			</form>
		</div>
	);
}

export default Vehicle;